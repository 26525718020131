.main {
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 100vh;
    background-color: var(--blue);
    padding-top: 3em;
    color: white;
    margin-inline: 40px;
}

.progress {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.266);
    margin-top: 15px;
    border-radius: 100px;
    height: 4px;
}

.progressBar {
    background-color: white;
    height: 4px;
    transition: all 0.3s ease;
    border-radius: 100px;
}

.mainHead {
    display: grid;
    place-items: center;
}

.btnWrap {
    align-self: flex-end;
    display: flex;
    gap: 14px;
    margin-bottom: 70px;
}

.btnWrap>button {
    padding-block: 13px;
    padding-inline: 20px;
    background-color: var(--white);
    border-radius: 5px;
    color: var(--blue);
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    font-family: "Raleway", sans-serif;
    border: none;
}

.btnWrap>button:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.30);
}

.btnWrap>button.submit:hover {
    color: white;
    background-color: #9bc8779b;
}

.btnWrap .submit {
    color: white;
    background-color: #9BC877;
}

.quesWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    flex: 1;
}

.ques textarea {
    resize: none;
    width: 100%;
    font-size: 17px;
}

.spacer {
    flex: 1;
}

.ques h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.options {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 14px 15px;
}

.option {
    background-color: rgba(255, 255, 255, 0.214);
    width: calc(50% - 7.5px);
    padding: 15px 18px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    border: 1.5px transparent solid;
    transition: 0.2s ease;
    font-weight: 500;
}

.option:hover {
    border: 1.5px white solid;
}

.option.selected {
    border: 1.5px white solid;
}

@media (max-width: 475px) {
    .main {
        margin-inline: 20px;
    }
    .main h1{
        font-size:1.7rem;
    }
    .ques h2{
        font-size:1.2rem;
    } 

    .ques textarea{
        font-size: 0.9rem;
    }
}