.main {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    background-color: var(--blue);
    padding-top: 6em;
    height: 100vh;
    color: white;
}

.main h1 {
    font-weight: 600;
}

.main h2 {
    font-size: 1.2rem;
    margin-top: 10px;
    font-weight: 500;
}

.main form {
    display: flex;
    flex-direction: column;
    width: 30em;
    margin-top: 8px;
    gap: 6px;
    padding-inline: 25px;
}

.main form label {
    margin-top: 10px;
    font-weight: 600;
}

input:focus,
textarea:focus {
    outline: none;
}

input {
    font-size: 0.9rem;
    padding: 11px;
    font-family: "Raleway", sans-serif;
    border: none;
    border-radius: 5px;
    padding-inline: 13px;
}

textarea {
    font-size: 0.9rem;
    padding: 13px;
    font-family: "Raleway", sans-serif;
    border: none;
    border-radius: 5px;
    padding-inline: 15px;
}

form button {
    padding-block: 13px;
    background-color: var(--white);
    border-radius: 5px;
    color: var(--blue);
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    margin-top: 25px;
    transition: all 0.2s ease-in-out;
    font-family: "Raleway", sans-serif;
    border: none;
}

form button:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.30);
}

@media (max-width: 475px) {
    .main form {
        width: 100%;
    }
}